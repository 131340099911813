import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'centCurrency'
})
export class CentCurrencyPipe  extends CurrencyPipe implements PipeTransform {

  transform(value: number): any{
    return super.transform(value / 100);
  }

}

import {PositionsName} from '../../pages/ranges/types/positions-name.type';
import {ExportAccessModes} from '../services/transfer.service';

export interface FlopRange {
  _id: string;
  name: string;
  folderId: string;
  cells: FlopRangeCell[];
  colorsEntity: FlopRangeColorEntityI[];
  pro?: boolean;
  order: number;
  rangeColor: string;
  rangeType: IRangeType;
  note: string;
  coverageCellIds: number[];
  manualCoverage: boolean;
  parentRangeId: string;
  parentColor: FlopRangeColorEntityI;
  childColors: FlopRangeColorEntityI[];
  tableData: FlopRangeTableDataI;
  accessMode: ExportAccessModes;
}

export interface FlopRangeCell {
  positionId: number;
  checked: boolean;
  colorId: number;
  limit: number;
}

export interface FlopRangeColorEntityI {
  id: number;
  hint: string;
  colors: FlopRangeColorFlatI[];
  limit: number;
}

export interface FlopRangeColorFlatI {
  color: string;
  frequency: number;
}

export interface FlopRangeTableDataI {
  enabled: boolean;
  position: number;
  numberOfPlayers: number;
  positions: TablePositionDataI[];
}

export interface TablePositionDataI {
  id: number;
  position: PositionsName;
  activePlayer: boolean;
  dealer: boolean;
  stack: number;
  bet: number;
  cards: string[];
}

export enum IRangeType {
  classic = 'classic',
  shortdeck = 'shortdeck'
}

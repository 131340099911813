import {Inject, Pipe, PipeTransform, PLATFORM_ID} from '@angular/core';
import {LanguageService} from '../services/language.service';
import {isPlatformBrowser} from '@angular/common';
import {TFbrLang} from '../enum/lang.enum';

@Pipe({
  name: 'anchorHref',
  pure: false
})
export class AnchorHrefPipe implements PipeTransform {
  constructor(private languageService: LanguageService,
    @Inject(PLATFORM_ID) private  platformId: Object) {
  }

  transform(lang, anchorElement): string {
    return isPlatformBrowser(this.platformId)
      ? `${location.origin}${location.pathname}#${anchorElement}`
      : `/${lang}#${anchorElement}`;
  }

}

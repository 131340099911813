import {FlopRange, FlopRangeCell, FlopRangeColorEntityI} from '../../../../../common/models/ranges.model';
import {RangeUtils} from '../../../../../common/utils/range.utils';
import {chain, uniq} from 'lodash-es';

export function deleteUnusedColors(range: FlopRange): FlopRange {
  const usedInCellsColorsIds: number[] = chain<any>(range.cells)
    .filter((cell: FlopRangeCell) => cell.checked)
    .map(cell => cell.colorId)
    .uniq()
    .value();
  const usedInCellColors = range.colorsEntity.filter(color => usedInCellsColorsIds.includes(color.id));
  const baseColors = range.colorsEntity.filter(color => RangeUtils.isFlopRangeBase(color));
  const usedGenericColors = chain(usedInCellColors)
    .filter((color: FlopRangeColorEntityI) => RangeUtils.isFlopRangeMixed(color))
    .map(color => {
      const cellColors = color.colors.map(c => c.color);
      return [color,  ...baseColors.filter((c) => cellColors.includes(c.colors[0].color))];
    })
    .flatMap()
    .uniq()
    .value();

  range.colorsEntity = uniq(usedInCellColors.concat(usedGenericColors));
  return range;
}

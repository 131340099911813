import {FlopRange, FlopRangeCell, FlopRangeColorEntityI, IRangeType} from '../models/ranges.model';
import {FlopRangeColorFlatI} from '../interfaces/flop-range-color-flat.interface';
import {flatMap, groupBy, maxBy, round, uniq} from 'lodash-es';
import {deleteUnusedColors} from '../../pages/ranges/pages/range-editor/functions/delete-unused-colors';

export const cardLetters = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'].reverse();
export const baseActionsHints = ['description', 'action', 'описание', 'descripción', 'descrizione', 'descrição'];

export class RangeUtils {

  static checkRangesEquality(range: FlopRange, secondRange: FlopRange): boolean {
    if (range == null && secondRange == null) {
      return true;
    }
    return this._checkCells(range.cells, secondRange.cells)
      && this._checkColors(range.colorsEntity, secondRange.colorsEntity)
      && range.note === secondRange.note
      && range.manualCoverage === secondRange.manualCoverage
      && range.rangeType === secondRange.rangeType
      && this._checkCoverage(range, secondRange);
  }

  static positionToCardName(position: number): string {
    const firstPos = Math.floor(position / 13);
    const secondPos = position % 13;
    const firstCardIndex = Math.max(firstPos, secondPos);
    const secondCardIndex = Math.min(firstPos, secondPos);
    const postfix = firstPos > secondPos ? 's' : firstPos < secondPos ? 'o' : '';
    //return `${firstPos} ${secondPos} ${postfix}`;
    return `${cardLetters[firstCardIndex]}${cardLetters[secondCardIndex]}${postfix}`;
  }

  static getCombinationCountsByPosition(x: number, y: number): number {
    if (Number(x) === Number(y)) {
      return 6;
    }
    if (x > y) {
      return 4;
    } else {
      return 12;
    }
  }

  static getRangeProb(range: FlopRange, onlyColor?: string): number {
    const cells = range.rangeType === IRangeType.shortdeck
      ? range.cells.filter(cell => {
        const firstPost = cell.positionId % 13;
        const secondPost = Math.floor(cell.positionId / 13);
        return firstPost >= 4 && secondPost >= 4;
      }) : range.cells;
    const checkedCells = cells.filter(cell => cell.checked);

    const cellColorsPercent = range.colorsEntity.reduce((acc, color) => Object.assign(acc, {
      [color.id]: this.getColorPercent(color, onlyColor)
    }), {});

    return checkedCells.reduce((acc, cell) => {
      const first = Math.floor(cell.positionId / 13);
      const second = cell.positionId % 13;
      const overallCombinations = RangeUtils.getCombinationCountsByPosition(first, second);
      const cellLimit = Number.isFinite(cell.limit) ? cell.limit : 100;
      return acc + (overallCombinations * cellColorsPercent[cell.colorId] * cellLimit / 100);
    }, 0);
  }

  static getRelatedRangeCombinations(range: FlopRange): number {
    const cells = range.rangeType === IRangeType.shortdeck
      ? range.cells.filter(({positionId}) => {
        const firstPos = Math.floor(positionId / 13);
        const secondPos = positionId % 13;
        return firstPos >= 4 && secondPos >= 4;
      })
      : range.cells;

    return cells
      .filter(c => c.limit > 0)
      .reduce((acc, cell) => {
        const positionId = cell.positionId;
        const firstPos = Math.floor(positionId / 13);
        const secondPos = positionId % 13;
        const cellLimit = Number.isFinite(cell.limit) ? cell.limit : 100;
        const value = firstPos === secondPos ? 6 : firstPos > secondPos ? 4 : 12;
        return acc + (value * cellLimit / 100);
      }, 0);
  }

  static adjustWithBaseColors(colors: FlopRangeColorEntityI[]): FlopRangeColorEntityI[] {
    if (!Array.isArray(colors)) {
      return [];
    }
    const mixedColors = colors.filter(color => this.isFlopRangeMixed(color));
    const baseColors = colors.filter(color => this.isFlopRangeBase(color));
    const mixedColorsStrs: string[] =
      uniq(
        flatMap(
          mixedColors.map(color => color.colors.map(c => c.color))
        )
      );
    const result: FlopRangeColorEntityI[] = [
      ...colors
    ];
    for (const color of mixedColorsStrs) {
      const baseColor = baseColors.find(bc => bc.colors[0].color === color);
      if (!baseColor) {
        const id = RangeUtils.getNewColorIndex(result);
        result.push({
          id,
          colors: [{
            color: color,
            frequency: 100
          }],
          hint: this.getNewColorHint(color, result),
          limit: 100
        } as FlopRangeColorEntityI);
      }
    }
    return result;
  }

  static getIndexByPosition(positionId: number): [number, number] {
    return [Math.floor(positionId / 13), positionId % 13];
  }

  static getCellColor(range: FlopRange, positionId: number): FlopRangeColorEntityI {
    if (range) {
      const cell = range.cells.find(c => c.positionId === positionId);
      return range.colorsEntity.find(color => color.id === cell.colorId);
    } else {
      return null;
    }
  }

  static getRangeOverallCombinations(type: IRangeType): number {
    return type === IRangeType.shortdeck ? 630 : 1326;
  }

  static getColorPercent(color: FlopRangeColorEntityI, onlyColor?: string): number {
    if (onlyColor) {
      const flatColor = color.colors.find(c => c.color === onlyColor);
      return !!flatColor ? (flatColor.frequency / 100) : 0;
    } else {
      const freq = RangeUtils.getFullFreq(color.colors);
      return freq / 100;
    }
  }

  static mergeRange(firstRange: FlopRange, secondRange: FlopRange): FlopRange {
    let result: FlopRange = JSON.parse(JSON.stringify(firstRange));
    const selectedInBothRangeCells = firstRange.cells
      .filter(cell => cell.checked)
      .filter(cell => secondRange.cells.find(c => c.positionId === cell.positionId && c.checked));
    const selectedOnlyInSecondRangeCells = firstRange.cells
      .filter(cell => !cell.checked)
      .filter(cell => secondRange.cells.find(c => c.positionId === cell.positionId && c.checked));
    for (const cell of selectedOnlyInSecondRangeCells) {
      const secondCell = secondRange.cells.find(c => c.positionId === cell.positionId);
      const secondColor = secondRange.colorsEntity.find(c => c.id === secondCell.colorId);
      this._mergeOrCreateColor(result, secondColor, cell.positionId);
    }

    for (const cell of selectedInBothRangeCells) {
      const secondCell = secondRange.cells.find(c => c.positionId === cell.positionId);
      const firstColor = firstRange.colorsEntity.find(c => c.id === cell.colorId);
      const secondColor = secondRange.colorsEntity.find(c => c.id === secondCell.colorId);
      // combine first and second colors arrays by color
      const mergedColor = {
        ...firstColor,
        colors: this._mergeFlatColors(firstColor.colors, secondColor.colors)
      };
      this._mergeOrCreateColor(result, mergedColor, cell.positionId);
    }

    result = deleteUnusedColors(result);

    for (const entity of result.colorsEntity) {
      const freq = RangeUtils.getFullFreq(entity.colors);
      if (freq > entity.limit + 2) {
        throw new Error('Frequency over limit');
      }
    }

    return result;
  }


  static getBaseFlopRangeColors(allColors: FlopRangeColorEntityI[]): FlopRangeColorEntityI[] {
    if (Array.isArray(allColors)) {
      return allColors.filter((color) => this.isFlopRangeBase(color));
    }
    return [];
  }

  static getMixedFlopRangeColors(allColors: FlopRangeColorEntityI[]): FlopRangeColorEntityI[] {
    if (Array.isArray(allColors)) {
      return allColors.filter((color) => this.isFlopRangeMixed(color));
    }
    return [];
  }

  static isFlopRangeBase(color: FlopRangeColorEntityI): boolean {
    if (!color) {
      return true;
    } else {
      return color.colors.length === 1 && color.colors[0].frequency === color.limit;
    }
  }

  static isFlopRangeMixed(color: FlopRangeColorEntityI): boolean {
    return !this.isFlopRangeBase(color);
  }

  static getCellTooltip(cell: FlopRangeCell, colors: FlopRangeColorEntityI[]): string {
    if (!cell.colorId) {
      return null;
    }
    const color = colors.find(c => c.id === cell.colorId);
    const isBase = this.isFlopRangeBase(color);
    if (isBase) {
      return color.hint;
    } else {
      const baseColors = this.getBaseFlopRangeColors(colors);
      const name = color.colors
        .map((c) => baseColors.find(bc => bc.colors[0].color === c.color))
        .map(c => c ? c.hint : null)
        .filter(c => !!c)
        .join(' / ');
      const frequency = color.colors.map(g => round(g.frequency)).join(' / ');
      return `${name}\n${frequency}`;
    }
  }

  static filterCellPositionsByType(cellPositions: number[], rangeType: IRangeType): number[] {
    return cellPositions.filter((id) => {
      if (rangeType === IRangeType.shortdeck) {
        const [firstPos, secondPos] = this.getIndexByPosition(id);
        return firstPos >= 4 && secondPos >= 4;
      } else {
        return id >= 0 && id < 169;
      }
    });
  }

  static getNewColorIndex(colors: FlopRangeColorEntityI[]): number {
    return colors.map(c => c.id).reduce((acc, res) => Math.max(acc, res), 0) + 1;
  }

  static colorsToSliderValues(colors: FlopRangeColorFlatI[]): { data: number[]; isNaturals: boolean } {
    const data = colors.reduce(
      (acc, item) => [...acc, item.frequency + (acc[acc.length - 1] || 0)],
      []
    );
    //check array contains only natural numbers
    const isNaturals = data.every((item) => item % 1 === 0);
    return {data, isNaturals};
  }

  static getFullFreq(colors: FlopRangeColorFlatI[]): number {
    return colors.reduce((acc, item) => acc + item.frequency, 0);
  }

  static getNewColorHint(color: string, colors: FlopRangeColorEntityI[]): string {
    const mixedColors = colors.filter(c => this.isFlopRangeMixed(c));
    const mixedColorWithOneColor = mixedColors.find(mixedColor => mixedColor.colors.length === 1 && mixedColor.colors[0].color === color);
    if (mixedColorWithOneColor && !baseActionsHints.some(hint => mixedColorWithOneColor.hint.includes(hint))) {
      return mixedColorWithOneColor.hint;
    } else {
      const baseColors = colors.filter(c => this.isFlopRangeBase(c));
      let index = 1;
      while (baseColors.some(baseColor => baseColor.hint === `action ${index}`)) {
        index++;
      }
      return `action ${index}`;
    }
  }


  private static _checkCells(cells: FlopRangeCell[], secondCells: FlopRangeCell[]): boolean {
    if (cells && secondCells && cells.length === secondCells.length) {
      return cells.every((cell, index) => {
        const secondCell = secondCells[index];
        if (cell.positionId === secondCell.positionId) {
          if (
            (cell.checked === false && secondCell.checked === false)
            || (cell.checked === true && secondCell.checked === true && cell.colorId === secondCell.colorId)
          ) {
            return true;
          }
        }
        return false;
      });
    }
  }

  private static _checkColors(colors: FlopRangeColorEntityI[], secondColors: FlopRangeColorEntityI[]): boolean {
    if (!colors && !secondColors) {
      return true;
    }
    if (colors && secondColors && (colors.length === secondColors.length)) {
      return colors.every((color, index) => {
        const secondColor = secondColors[index];
        if (color.limit !== secondColor.limit || color.hint !== secondColor.hint) {
          return false;
        }
        return color.colors.length === secondColor.colors.length
          && color.colors.every((c, i) => {
            const secondC = secondColor.colors[i];
            return c.color === secondC.color && c.frequency === secondC.frequency;
          });
      });
    }
  }

  private static _checkCoverage(range: FlopRange, secondRange: FlopRange): boolean {
    if (!range.manualCoverage && !secondRange.manualCoverage) {
      return true;
    } else {
      const coverageCells = range.coverageCellIds;
      const secondCoverageCells = secondRange.coverageCellIds;
      if (Array.isArray(coverageCells) && Array.isArray(secondCoverageCells)) {
        return coverageCells.every(cell => secondCoverageCells.includes(cell));
      } else {
        return coverageCells === secondCoverageCells;
      }
    }

  }


  private static _colorsEqual(color1: FlopRangeColorEntityI, color2: FlopRangeColorEntityI): boolean {
    return color1.colors.length === color2.colors.length
      && color1.colors.every((c, i) => c.color === color2.colors[i].color && c.frequency === color2.colors[i].frequency);
  }


  private static _mergeFlatColors(colors1: FlopRangeColorFlatI[], colors2: FlopRangeColorFlatI[]): FlopRangeColorFlatI[] {
    return Object.entries(
      groupBy([
        ...colors1,
        ...colors2
      ], 'color')
    ).reduce((acc, [c, colors]) => ([
      ...acc,
      {color: c, frequency: RangeUtils.getFullFreq(colors)}
    ]), []);
  }

  private static _mergeOrCreateColor(originRange: FlopRange, newColor: FlopRangeColorEntityI, positionId: number): void {
    const color = originRange.colorsEntity.find(c => this._colorsEqual(c, newColor));
    const cell = originRange.cells.find(c => c.positionId === positionId);
    if (color) {
      cell.colorId = color.id;
      cell.checked = true;
    } else {
      const newId = (maxBy(originRange.colorsEntity, (c) => c.id)?.id || 0) + 1;
      originRange.colorsEntity.push({
        ...newColor,
        id: newId
      });
      cell.colorId = newId;
      cell.checked = true;
    }
  }


}

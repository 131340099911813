import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {lastValueFrom, Observable} from 'rxjs';
import {Api2Service} from './api2.service';
import {FbrUserModel} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly api2Service: Api2Service) {
  }

  public getUser(): Promise<FbrUserModel> {
    return lastValueFrom(this.api2Service.get<any>('public/auth/user')).then((user) => ({
      ...user,
      payedEndDate: user.payedEndDate ? new Date(user.payedEndDate) : null,
      trialEndDate: user.trialEndDate ? new Date(user.trialEndDate) : null
    }))
  }

  public activateTrial(): Observable<void> {
    return this.api2Service.post<void>('public/auth/user/trial/activate', {});
  }

  public unpro(): Observable<void> {
    return this.api2Service.post('public/auth/user/unpro', {});
  }

  public initCurrentLanguage(language): Observable<void> {
    return this.api2Service.put<void>('public/auth/user/set-language', {language});
  }

}

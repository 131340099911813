import {computed, inject, Injectable, PLATFORM_ID, signal} from '@angular/core';
import {SettingsI} from './interfaces/settings.interface';
import {ColorUtils} from '../../utils/color.utils';
import {CellTextColor} from './enums/cell-text-color-options.enum';
import {GeneralSettingsI} from './interfaces/general-settings.interface';
import {UserSettingsService} from './user-settings.service';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {EditorSettingsI} from './interfaces/editor-settings.interface';
import {TrainerSettingsI} from './interfaces/trainer-settings.interface';
import {ViewerSettingsI} from './interfaces/viewer-settings.interface';
import {LanguageService} from '../../services/language.service';
import {TFbrLang} from '../../enum/lang.enum';
import {isPlatformBrowser, Location} from '@angular/common';
import {UserService} from '../../services/user.service';

@Injectable({providedIn: 'root'})
export class UserSettingsStore {
  userSettingsService = inject(UserSettingsService);
  userService = inject(UserService);
  languageService = inject(LanguageService);
  location= inject(Location);
  platformId = inject(PLATFORM_ID);

  settings = signal<SettingsI>({
    language: 'en',
    presetColors: ColorUtils.presetColors,
    cellTextColor: CellTextColor.White,
    rngUsage: 'auto',
    rngOrientation: 'ltr',
    showOriginalRange: 'auto',
    folderOrientation: 'horizontal',
    randomizer: false,
    randomizerType: 'manual',
    randomizerTimerValue: 5,
    showFirst: 'by-order',
    splitFolderRanges: false,
    hideRange: false,
    fullNames: false,
    showRoundFreq: false,
    roundStep: 25
  });
  generalSettings = computed<GeneralSettingsI>((() => ({
    language: this.settings().language
  })));
  editorSettings = computed<EditorSettingsI>(() => {
    const settings = this.settings();
    return {
      presetColors: settings.presetColors,
      cellTextColor: settings.cellTextColor,
      showRoundFreq: settings.showRoundFreq,
      roundStep: settings.roundStep
    }
  });

  viewerSettings = computed<ViewerSettingsI>(() => {
    const settings = this.settings();
    return {
      randomizer: settings.randomizer,
      randomizerType: settings.randomizerType,
      randomizerTimerValue: settings.randomizerTimerValue,
      splitFolderRanges: settings.splitFolderRanges,
      showFirst: settings.showFirst,
      hideRange: settings.hideRange,
      folderOrientation: settings.folderOrientation,
      fullNames: settings.fullNames
    }
  });


  trainerSettings = computed<TrainerSettingsI>(() => {
    const settings = this.settings();
    return {
      rngUsage: settings.rngUsage,
      rngOrientation: settings.rngOrientation,
      showOriginalRange: settings.showOriginalRange
    }
  });

  presetColors = computed(() => this.settings().presetColors);

  async saveSettings(settings: SettingsI) {
    const language = settings.language as TFbrLang ;
    const res = await lastValueFrom(this.userSettingsService.saveUserSettings({
      ...this.settings(),
      ...settings
    }));
    await this._checkLanguage(language);
    this.settings.set({
      ...res
    })
  }

  setSettings(settings: SettingsI) {
    this.settings.set({
      ...settings
    });
  }

  async init() {
    const res = await firstValueFrom(this.userSettingsService.getUserSettings());
    this.settings.set({
      ...this.settings(),
      ...res
    });
  }



  private async _checkLanguage(language: string) {
    if (language && language !== this.settings().language) {
      this.languageService.setLanguage(language as TFbrLang);
      await lastValueFrom(this.userService.initCurrentLanguage(language));
      if (isPlatformBrowser(this.platformId)) {
        const pathElements = window.location.pathname.toString().split('/').filter(t => !!t);
        pathElements.shift();
        const newUrl = `${this.languageService.getLanguage()}/${pathElements.join('/')}`;
        this.location.go(newUrl);
      }
    }
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Api2Service} from '../../services/api2.service';
import {SettingsI} from './interfaces/settings.interface';

const USER_SETTINGS_API = 'public/user-settings';

@Injectable({providedIn: 'root'})
export class UserSettingsService {

  constructor(
    private readonly api2: Api2Service
  ) { }

  getUserSettings(): Observable<any> {
    return this.api2.get<{settings: SettingsI}>(USER_SETTINGS_API).pipe(
      map(data => data?.settings)
    );
  }

  saveUserSettings(settings: any): Observable<any> {
    return this.api2.post<{settings: SettingsI}>(USER_SETTINGS_API, settings).pipe(
      map(data => data?.settings)
    );
  }
}

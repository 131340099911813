import {FlopRangeColorEntityI, FlopRangeColorFlatI} from '../models/ranges.model';
import {RangeUtils} from './range.utils';

export class ColorUtils {
  static createGradient(gradArray: FlopRangeColorFlatI[], opacity = 1, limit = 100, currentTheme: string): string {
    if (Array.isArray(gradArray) && gradArray.length > 0) {
      const frequencies = RangeUtils.colorsToSliderValues(gradArray);
      const gradStrings = gradArray.map((grad, i) => {
        const color = this.colorToRgba(grad.color, opacity);
        const prevGradFrequency = i > 0 ? frequencies.data[i - 1] : 0;
        return `${color} ${prevGradFrequency}%, ${color} ${frequencies.data[i]}%`;
      });
      const fullFrequency = RangeUtils.getFullFreq(gradArray);
      if (fullFrequency < limit) {
        const baseThemeColor = currentTheme === 'light' ? '#d0d0d0' : '#3F424A';
        const baseThemeColoRgba = this.colorToRgba(baseThemeColor, opacity);
        gradStrings.push(
          `${baseThemeColoRgba} ${fullFrequency}%, ${baseThemeColor} 100%`
        );
      }
      return `linear-gradient(to right, ${gradStrings.join(', ')})`;
    } else {
      return '';
    }
  }

  static getColorBackgroundFromItem(colorItem: FlopRangeColorEntityI, currentTheme: string): string {
    const isBase = RangeUtils.isFlopRangeBase(colorItem);
    if (isBase) {
      return colorItem?.colors[0]?.color || '' ;
    } else {
      return ColorUtils.createGradient(colorItem.colors, 1, 100, currentTheme);
    }
  }

  static colorToRgba(h, opacity): string{
    const colorStr = h.toLowerCase();
    const hexRegexp = /^#([A-Fa-f0-9]{3}){1,2}$/;
    const rgbRegexp = /^rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)/;
    const rgbaRegexp = /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/;
    const rgbaRegexpGlobal = /rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/g;
    if (h.includes('linear-gradient')) {
      return h.replace(rgbaRegexpGlobal, (match, r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${opacity})`);
    } else if(hexRegexp.test(colorStr)){
      let c= colorStr.substring(1).split('');
      if(c.length === 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(', ')+',' + opacity +')';
    } else if (rgbRegexp.test(colorStr)) {
      const matchRes = rgbRegexp.exec(colorStr);
      return `rgba(${matchRes[1]}, ${matchRes[2]}, ${matchRes[3]}, ${opacity})`;
    } else if (rgbaRegexp.test(colorStr)) {
      const matchRes = rgbaRegexp.exec(colorStr);
      const opacityRgba = Number(matchRes[4]);
      return `rgba(${matchRes[1]}, ${matchRes[2]}, ${matchRes[3]}, ${opacity * opacityRgba})`;
    }
    throw new Error('Bad Color');
  }

  static hexToRgb(hex): number[] {
    const hexRegexp = /^#([A-Fa-f0-9]{3}){1,2}$/;
    if (hexRegexp.test(hex)) {
      let c= hex.substring(1).split('');
      if(c.length === 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return [(c>>16)&255, (c>>8)&255, c&255];
    }
    throw new Error('Bad Color');
  }

  static extractRGBAWithMaxPercentage(gradientString): {color: number[]; percentage: number} {
    const rgbaWithPercentageRegex = /rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(?:,\s*([0-1]?\.?\d*))?\)\s*(\d{1,3}%)?/g;

    let maxPercentageItem = null;
    let previousPercentage = 0;
    let match;

    while ((match = rgbaWithPercentageRegex.exec(gradientString)) !== null) {
      const r = parseInt(match[1], 10);
      const g = parseInt(match[2], 10);
      const b = parseInt(match[3], 10);
      const a = match[4] !== undefined ? parseFloat(match[4]) : 1;

      const percentage = match[5] ? parseInt(match[5], 10) : null;
      if (percentage !== null) {
        const adjustedPercentage = percentage - previousPercentage;
        previousPercentage = percentage;

        // Check if this item has the max percentage so far
        if (!maxPercentageItem || adjustedPercentage > maxPercentageItem.percentage) {
          maxPercentageItem = {color: [r, g, b, a], percentage: adjustedPercentage};
        }
      }
    }

    return maxPercentageItem;
  }
  //linear gradient to rgba


  static presetColors = [
    '#9b5378', '#79a65a', '#3f424a', '#e55656', '#db9713',
    '#4b9ce7', '#3d6b95', '#1abc9c', '#727e8b', '#939521',
    '#8d6821', '#992e2e', '#707070', '#61489B', '#da7182'
  ];
}

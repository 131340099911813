import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CentCurrencyPipe} from './cent-currency.pipe';
import {ReversePipe} from './reverse.pipe';
import { AnchorHrefPipe } from './anchor-href.pipe';
import { LocalizedLinksPipe } from './localized-links.pipe';



@NgModule({
  declarations: [
    CentCurrencyPipe,
    ReversePipe,
    AnchorHrefPipe,
    LocalizedLinksPipe
  ],
  exports: [
    CentCurrencyPipe,
    ReversePipe,
    AnchorHrefPipe,
    LocalizedLinksPipe
  ],
  imports: [
    CommonModule,
  ]
})
export class CommonPipesModule { }
